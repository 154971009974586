<template>
    <div class="fols">
        <div>
            <h1 class="olsss">{{ $t('ourwines.title') }}</h1>
            <p class="tdfol">{{ $t('ourwines.title1') }}</p>
            <p class="tdfol2">{{ $t('ourwines.content1') }}</p>
            <p class="tdfol">{{ $t('ourwines.title2') }}</p>
            <p class="tdfol2">{{ $t('ourwines.content2') }}</p>
        </div>
                        
        <div class="div5saa">
            <v-card class="csr5sd">
                <div class="divufsea"></div>
                <!-- <v-img class="imgf6a" src="@/assets/winesour2.jpeg"></v-img> -->
                <div class="dia5c">
                    <h1 class="foldx">Robiza Sea</h1>
                    <p class="dol5ww">{{ $t('ourwines.cont2') }}
                    <a class="dol5ww" @click="seashowtex = !seashowtex"><span class="spdolv">{{ !seashowtex ? '... [leer más]' : '' }}</span></a></p>
                    <p class="dol5ww" v-show="seashowtex">
                    {{ $t('ourwines.cont2-1') }}<br>
                    {{ $t('ourwines.cont2-2') }}
                    <a @click="seashowtex = !seashowtex"><span class="spdolv">{{ seashowtex ? '... [leer menos]' : '' }}</span></a></p>
                </div>
            </v-card>
            <v-card class="csr5sd">
                <div class="divufsun"></div>
                <!-- <v-img class="imgf6a" src="@/assets/winesour1.jpg"></v-img> -->
                <div class="dia5c">
                    <h1 class="foldx">Robiza Sun</h1>
                    <p class="dol5ww">{{ $t('ourwines.cont1') }}
                    <a class="dol5ww" @click="sunsetshowtex = !sunsetshowtex"><span class="spdolv">{{ !sunsetshowtex ? '... [leer más]' : '' }}</span></a></p>
                    <p class="dol5ww" v-show="sunsetshowtex">
                    {{ $t('ourwines.cont1-2') }}
                    <a @click="sunsetshowtex = !sunsetshowtex"><span class="spdolv">{{ sunsetshowtex ? '... [leer menos]' : '' }}</span></a></p>
                </div>
            </v-card>
            <v-card class="csr5sd">
                <div class="divufmoon"></div>
                <!-- <v-img class="imgf6a" src="@/assets/winesour3.jpg"></v-img> -->
                <div class="dia5c">
                    <h1 class="foldx">Robiza Moon</h1>
                    <p class="dol5ww">{{ $t('ourwines.cont3') }}
                    <a class="dol5ww" @click="moonshowtex = !moonshowtex"><span class="spdolv">{{ !moonshowtex ? '... [leer más]' : '' }}</span></a></p>
                    <p class="dol5ww" v-show="moonshowtex">
                    {{ $t('ourwines.cont3-1') }}
                    <a @click="moonshowtex = !moonshowtex"><span class="spdolv">{{ moonshowtex ? '... [leer menos]' : '' }}</span></a></p>
                </div>
            </v-card>
        </div>
    </div>
</template>


<style>
.imgf6a {
    height: 500px;
}

.divufsea{
  background-image: url(../assets/winesour2.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover ;
  height: 500px;
}

.divufsun{
  background-image: url(../assets/winesour1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover ;
  height: 500px;
}

.divufmoon{
  background-image: url(../assets/winesour3.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover ;
  height: 500px;
}

.div5saa {
    /* align-items: flex-end; */
    display: flex;
    /* background: #a98d7e; */
    /* box-sizing: border-box; */
    /* justify-content: center; */
}

.dia5c {
    padding: 10%;
}

.dol5ww {
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: white;
}

.spdolv {
    color: white;
    font-weight: 100;
}

.foldx {
    text-align: center;
    padding-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
    color: #f3976a;
}

.csr5sd {
    margin-top: 30px;
    width: 300px;
    /* height: auto; */
    margin-left: 3%;
    margin-right: 3%;
    border-radius: 5px 25px 5px 25px !important;
    background: rgba(59, 59, 59, 0.5) !important ;
}

.fols {
    /* background-image: url(../assets/mint4.jpg); */
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    padding-bottom: 100px;
    padding-left: 15%;
    padding-right: 15%;
    /* opacity: 0.9; */
}

.olsss {
  text-align: center;
  font-size: 50px;
  padding-top: 50px;
  padding-bottom: 30px;
  font-family: "Montserrat",Sans-serif;
  font-weight: 300;
  color: white;
}

.tdfol {
    /* padding-bottom: 20px; */
    font-size: 18px;
    text-align: justify;
    font-family: "Montserrat",Sans-serif;
    font-weight: 500;
    color: #f3976a;
    letter-spacing: 2px;
}

.tdfol2 {
    font-family: "Montserrat",Sans-serif;
    padding-bottom: 20px;
    font-size: 16px;
    /* color: aliceblue; */
    text-align: justify;
    font-weight: 400;

    /* font-weight: 400;
    font-size: 15px; */
    color: white !important;
}

@media all and (min-width: 960px) and (max-width: 1264px) { 
    .fols {
        padding-left: 10%;
        padding-right: 10%;
    }
    .csr5sd {
        width: 250px;
        /* height: 1000px; */
    }
    .foldx {
        padding-bottom: 20px;
        font-size: 20px;
    }
    .dol5ww {
        font-size: 13.5px;
    }
    .imgf6a {
        height: 400px;
    }
}

@media all and (min-width: 600px) and (max-width: 960px) {
    .div5saa {
        display: inline;
    }
    .csr5sd {
        margin-left: 0;
        margin-right: 0;
        margin: auto;
        width: 250px;
        /* height: 1000px; */
        margin-bottom: 50px;
    }
    .foldx {
        padding-bottom: 20px;
        font-size: 20px;
    }
    .dol5ww {
        font-size: 13.5px;
    }
    .imgf6a {
        height: 400px;
    }
    .fols {
        padding-bottom: 50px;
    }
}

@media all and (max-width: 600px) {
    .div5saa {
        display: inline;
    }
    .csr5sd {
        margin-left: 0;
        margin-right: 0;
        margin: auto;
        width: 250px;
        /* height: auto; */
        margin-bottom: 70px;
        margin-top: 50px;
    }
    .foldx {
        padding-bottom: 20px;
        font-size: 20px;
    }
    .dol5ww {
        font-size: 13.5px;
    }
    .imgf6a {
        height: 400px;
    }

    .divufsea{
    background-image: url(../assets/botrobvolsea.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover ;
    height: 170px;
    }

    .divufsun{
    background-image: url(../assets/botrobvolsun.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover ;
    height: 170px;
    }

    .divufmoon{
    background-image: url(../assets/botrobvolmoon.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover ;
    height: 160px;
    }

    .olsss {
        font-size: 40px;
    }
    .tdfol {
        font-size: 16px;
    }
    .tdfol2 {
        font-size: 16px;
    }
    .fols {
        padding-bottom: 50px;
    }
}
</style>


<script>

export default {
  name: 'OurWines',
  components: {
  },

  data: () => {
    return {
        seashowtex: false,
        sunsetshowtex: false,
        moonshowtex: false,
    }
  },
}
</script>