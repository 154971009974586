<template>
  <div class="divs5acvv">
    <h1 class="h1scce">{{ $t('joinclub.title') }}</h1>
    <div class="car6d5as">
      <h1 class="h14sds">{{ $t('joinclub.content') }}</h1>
      <v-flex class="flexcontact">
        <v-form ref="formSubmit" v-model="valid">

          <v-layout>
            <div class="dols6">
              <v-text-field v-model="firstname" :rules="nameRules" required class="textform" dense label="First Name*" color="#f3976a" type="text"></v-text-field>
            </div>
            <div class="dols6">
              <v-text-field v-model="lastname" :rules="lastRules" required class="textform" dense label="Last Name*" color="#f3976a" type="text"></v-text-field>
            </div>
          </v-layout>

            <div class="dols6des">
              <v-text-field v-model="email" :rules="emailRules" required dense label="E-mail*" color="#f3976a"></v-text-field>
            </div>
            <div class="dols6des">
              <v-textarea v-model="description" :rules="descriptionRules" required dense label="Welcome presentation" color="#f3976a"></v-textarea>
            </div>

            <div class="divche">
              <v-checkbox v-model="checkbox" :rules="checkboxRules" color="#f3976a">
                <template v-slot:label>
                  <div>
                    {{ $t('joinclub.check') }}
                  </div>
                </template>
              </v-checkbox>
            </div>

          <div class="idolbut">
            <v-btn class="fornbtn" color="#f3976a" outlined @click="onSubmit" v-model="valid">{{ $t('joinclub.btn') }}</v-btn>
          </div>

          <div>
            <h3 class="result" v-if="status == 1">{{ complete }}</h3>
            <h3 class="inresult" v-else-if="status == 2">{{ incomplete }}</h3>
          </div>

        </v-form>
      </v-flex>
    </div>
  </div>
</template>


<script>
import { db } from '../main';
import { serverTimestamp } from "firebase/firestore";

export default {
  name: 'JoinClub',
  components: {
  },

  data: () => ({
    valid: false,
    complete: "",
    incomplete: "",
    status: "",
    joinclub: {},
    firstname: '',
    nameRules: [
      v => !!v || 'First Name is required',
      v => v.length >= 3 || 'First Name must be less than 3 characters',
    ],
    lastname: '',
    lastRules: [
      v => !!v || 'Last Name is required',
      v => v.length >= 3 || 'Last Name must be less than 3 characters',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    description: '',
    descriptionRules: [
      v => !!v || 'Description is required',
      v => v.length >= 10 || 'Description must be less than 10 characters',
    ],
    checkbox: false,
    checkboxRules: [
      v => !!v || "You must accept our terms and conditions",
    ],
  }),

  methods: {
    async onSubmit() {
      this.$refs.formSubmit.validate() 
      try {
        if(this.firstname && this.lastname && this.email && this.description) {

          await db.collection('JoinClub').add({
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            description: this.description,
            status: false,
            createdAt: serverTimestamp()
          })

          this.getEvents();

          this.complete = 'Thanks! We will contact you soon';
          this.resetValidation();
          this.clear();
          this.status = 1;
    
        } else {
          this.incomplete = 'Please complete the required fields';
          this.status = 2;
        }

      } catch (error) {
          console.log(error)  
      }
    },

    resetValidation() {
      this.$refs.formSubmit.resetValidation()
    },

    clear() {
      this.firstname = ''
      this.lastname = ''
      this.email = ''
      this.description = ''
      this.checkbox = false
    },

    async getEvents() {
      try {
        const snapshot = await db.collection('JoinClub').get();
        const joinclub = [];
        snapshot.forEach(doc => {
          let eventoData = doc.data();
          eventoData.id = doc.id;
          joinclub.push(eventoData);
        })
      } catch (error) {
          console.log(error);
      }
    },
  },

  created() {
    this.getEvents();
  },
}
</script>


<style>
.result, .inresult {
  text-align: center;
  padding-bottom: 30px;
}

.h1scce {
  color: gray;
  padding-top: 35px;
  padding-bottom: 35px;
  font-family: 'Anton', sans-serif;
  font-size: 100px;
  font-weight: 100;
  opacity: 0.7;
  text-align: center;
}

.divche {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
}

.idolbut {
  text-align: center;
  padding-bottom: 30px;
}

.dols6 {
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}

.dols6des {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}

.flexcontact {
  padding-left: 30px;
  padding-right: 30px;
}

.h14sds {
  font-family: "Montserrat",Sans-serif;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 20px;
}

.car6d5as {
  padding-top: 20px;
  margin: auto;
  width: 40%;
  background: aliceblue;
  opacity: 0.8;
  border: solid;
  border-radius: 7px;
  border-width: 1px;
  color: #f3976a;
}

.divs5acvv {
  padding-bottom: 100px;
  background-image: url(../assets/textneg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

@media all and (min-width: 960px) and (max-width: 1264px) { 
  .h1scce {
    font-size: 80px;
  }
  .car6d5as {
    width: 60%;
  }
}

@media all and (min-width: 600px) and (max-width: 960px) {
  .h1scce {
    font-size: 60px;
  }
   .car6d5as {
    width: 60%;
  }
  .h14sds {
    font-size: 25px;
  }
  .dols6 {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }

  .dols6des {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }
}

@media all and (max-width: 600px) {
 .h1scce {
   font-size: 40px;
 }
 .h14sds {
  font-size: 20px;
  padding-bottom: 15px; 
  }
  .car6d5as {
    padding-top: 15px;
    width: 80%;
  }
  .dols6 {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
  }

  .dols6des {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
  }
}
</style>
