<template>
  <v-container fluid class="contpre" id="presaleStarted">
      <!-- <vue-particles></vue-particles> -->
      <div class="card111111111">
      <v-img class="fondo1">
        <h1 class="h1tpur" align="center" v-if="getCurrentRate == 0">{{ $t('presalestart.pre1') }}</h1>
        <h1 class="h1tpur" align="center" v-else-if="getCurrentRate == 80000">{{ $t('presalestart.pre2') }}</h1>
        <h1 class="h1tpur" align="center" v-else-if="getCurrentRate == 65000">{{ $t('presalestart.pre2') }}</h1>
        <!-- <h1 class="h1tpur" align="center" v-else-if="getCurrentRate == 50000">{{ $t('presalestart.pre2') }}</h1>
        <h1 class="h1tpur" align="center" v-else-if="getCurrentRate == 40000">{{ $t('presalestart.pre2') }}</h1> -->
        <h1 class="h1tpur" align="center" v-else-if="getCurrentRate == 1">{{ $t('presalestart.pre3') }}</h1>

        <div class="assaq3s22">
          <div class="divprocir22">
            <v-progress-circular class="progpur" :rotate="-90" :size="size1" :width="width1" :value="(100 / 60 ) * days" color="#f3976a">{{ days }}</v-progress-circular>
            <h3 class="days54">{{ $t('presalestart.day') }}</h3>
          </div>

          <div class="divprocir22">
            <v-progress-circular class="progpur" :rotate="-90" :size="size1" :width="width1" :value="(100 / 24 ) * hours" color="#f3976a">{{ hours }}</v-progress-circular>
            <h3 class="days54">{{ $t('presalestart.hour') }}</h3>            
          </div>

          <div class="divprocir22">
            <v-progress-circular class="progpur" :rotate="-90" :size="size1" :width="width1" :value="(100 / 60 ) * minutes" color="#f3976a">{{ minutes }}</v-progress-circular>
            <h3 class="days54">{{ $t('presalestart.minute') }}</h3>            
          </div>

          <div class="divprocir22">
            <v-progress-circular class="progpur" :rotate="-90" :size="size1" :width="width1" :value="(100 / 60 ) * seconds" color="#f3976a">{{ seconds }}</v-progress-circular>
            <h3 class="days54">{{ $t('presalestart.second') }}</h3>            
          </div>
        </div>
       
        <h1 class="h1titeld5" align="center" v-if="getCurrentRate == 80000">{{ $t('presalestart.predown2') }}</h1>
        <h1 class="h1titeld5" align="center" v-else-if="getCurrentRate == 65000">{{ $t('presalestart.predown3') }}</h1>
        <!-- <h1 class="h1titeld5" align="center" v-else-if="getCurrentRate == 50000">{{ $t('presalestart.predown4') }}</h1>
        <h1 class="h1titeld5" align="center" v-else-if="getCurrentRate == 40000">{{ $t('presalestart.predown5') }}</h1> -->
        <h1 class="h1titeld5" align="center" v-else-if="getCurrentRate == 0">{{ $t('presalestart.predown1') }}</h1>
        <h1 class="h1titeld5" align="center" v-else-if="getCurrentRate == 1">{{ $t('presalestart.predown6') }}</h1>
        
        <h4 class="h4pur" align="left">{{ $t('presalestart.progress') }} {{ value }} %</h4>

        <v-layout class="layprogress">
          <v-progress-linear class="proglinear" color="#f3976a" height="13" :value="value" striped></v-progress-linear>
        </v-layout>

        <div class="divldsx">
          <div class="divldacc"><h2 class="divpur">{{ $t('presalestart.av') }}</h2>
            <h3 class="h3sdf" v-if="databsc.message === 'OK'">{{ databsc.result / 1e+18 }} RBZ</h3>
          </div>

          <div class="divldacc"><h2 class="divpur">{{ $t('presalestart.sold') }}</h2>
            <h3 class="h3sdf">{{ tokenSold }} RBZ</h3>
          </div>
        </div>

        <v-layout class="justify-center">
          <span class="btnpur font-weight-black" dark large v-if="ethAccount"></span> 
          <v-btn class="btnpur" small color="#f3976a" dark v-else-if="installed" @click="getAccount()">{{ $t('presalestart.btn2') }}</v-btn>
          <v-btn class="btnpur" small color="#f3976a" dark v-else @click="getMetamask()">{{ $t('presalestart.btn1') }}</v-btn>
        </v-layout>

        <v-layout class="justify-center">
          <span class="btnpur" dark large v-if="ethAccount"></span>
          <v-btn class="btnpurdsqsd" small color="#f3976a" dark v-else @click="addToMetamask()">Add to Metamask</v-btn>
        </v-layout>
      </v-img>

      <v-flex class="flexnew" v-show="ethAccount">
        <v-flex class="flex5dsdane">
          <v-flex class="weds54">
            <h4 class="justify-center">{{ $t('presalestart.acc') }}</h4>
            <v-flex class="cardwew" outlined>
              <textarea v-model="ethAccount" readonly="readonly" ref="wall" class="textarea11"></textarea>
              <v-btn dark x-small text @click="wall" class="btn54"><v-icon small redonded>mdi-content-copy</v-icon></v-btn>
            </v-flex>
          </v-flex>

          <v-flex id="app">
            <h4 class="">{{ $t('presalestart.accamount') }}</h4>
            <p class="ppp">{{ tokenBalance }} RBZ</p>
          </v-flex>

          <v-layout class="flextextfd">
            <v-text-field id="numberToken" dark class="textformpre" required placeholder="1 BNB" label="Amount to buy" :rules="rules" type="number" @keyup="numberTokenM" v-model="showAmount" outlined>
              <template slot="append">
                <span class="usericonbox"><img class="token-logo__logo" src="@/assets/bnbpre.png" alt="Token logo">BNB</span>
              </template>
            </v-text-field>
          </v-layout>

          <p class="h44cadcala">1 BNB = {{ getCurrentRate }} RBZ</p>

          <v-layout class="flextextfd21">
            <v-text-field id="numberToken" dark class="textformpre" placeholder="Recive Amount" label="You Get" type="number" @keyup="numberTokenM" v-model="squaredResult" outlined>
              <template slot="append">
                <span class="usericonbox"><img class="token-logo__logo" src="@/assets/rbzpre.png" alt="Token logo">RBZ</span>
              </template>
            </v-text-field>            
          </v-layout>

          <v-layout justify-center>
            <span class="h44cadcal22">20% Bonus:</span>
            <span class="h44cadcal"> {{ bonus1Bnb }} RBZ</span>
          </v-layout>

          <v-layout class="laydfsss" justify-center>
            <span class="h44cadcal22">Total purchase:</span>
            <p class="h44cadcal">{{ squaredResult + bonus1Bnb }} RBZ</p>
          </v-layout>

            <v-layout class="flexbutn justify-center">
            <v-btn class="btn primaryBtn" color="#f3976a" small dark @click="sendEthTransaction()" id="numberToken">{{ $t('presalestart.btnbuy') }}</v-btn>
          </v-layout>

          <v-layout class=" justify-center">
            <v-btn class="btnpurdsqq" color="#f3976a" small dark @click="addToMetamask()">Add to Metamask</v-btn>
          </v-layout>

          <v-flex v-show="Tx">
            <h4 class="gols51  justify-center">Transaction Hash</h4>
            <v-flex class="cardwew" outlined>
              <textarea v-model="Tx" readonly="readonly" ref="walltx" class="textarea11tx"></textarea>
              <v-btn dark x-small text @click="walltx" class="btn54"><v-icon small redonded>mdi-content-copy</v-icon></v-btn>
            </v-flex>
          </v-flex>
        </v-flex>
      </v-flex>
    </div>
  </v-container>
</template>


<style>
.assaq3s22 {
  text-align: center;
}

.divldsx {
  text-align: center;
}

.divldacc {
  display: inline-block;
  margin-left: 100px;
  margin-right: 100px;
}

.divprocir22 {
  display: inline-block;
  margin-left: 50px;
  margin-right: 10px;
  margin-top: 10px;
}

.flexnew {
  margin-top: -40px;
}

.laydfsss {
  margin-top: 15px;
}

.flextextfd21 {
  margin-top: 20px;
}

.buy-box-input {
    height: 60px;
    font-size: 16px;
    line-height: 16px;
    margin-top: 30px;
    display: flex;
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
}

.label-input__dark {
    position: absolute;
    display: flex;
    align-items: center;
    top: 5px;
    left: 20px;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.now-input__dark {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 17px 20px 0;
    font-size: 24px;
    color: #fff;
    background-color: #3e3e59;
    border-radius: 5px 0 0 5px;
    outline: none;
    border: 1px solid #333;
}

button, input {
    overflow: visible;
}

.usericonbox {
    position: absolute;
    text-align: center;
    top: 2px;
    right: 10px;
    width: 50px;
    height: 60px;
    padding-top: 5px;
}

i, span, a {
    display: inline-block;
}

.h1titeld5 {
  color: aliceblue;
  font-weight: 100;
}

.h3sdf {
  font-size: 20px;
  color: aliceblue;
  font-weight: 100;
}

.card111111111{
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  box-shadow: 0 0 45px #f3976a;
  /* background: rgba(56, 75, 105, 0.5) !important; */
  /* border-radius: 7px; */
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  width: 70%;
}

.h44cadcal22 {
  color: aliceblue;
  font-size: 20px;
  font-weight: 600;
}

.ppp {
  color: aliceblue;
  margin-top: 5px;
}

.h44cadcal {
  color: aliceblue;
  font-size: 20px;
  font-weight: 100;
  padding-left: 10px;
}

.h44cadcala {
  color: aliceblue;
  font-size: 18px;
  font-weight: 100;
  padding-left: 10px;
}

.cardcal {
 padding-left: 20px;
 padding-right: 20px;
  padding-top: 15px;
  margin-bottom: 20px;
}

.divpur {
  font-size: 27px;
  color: aliceblue;
  font-weight: 100;
}

.days54 {
  font-size: 20px;
  text-align: center;
  color: aliceblue;
  font-weight: 100;
  margin-top: 15px;
  margin-bottom: 20px;
}

.numsad3 {
  width: 21%;  /* Cambiar tama;o manualmente a medida que disminulla el numero inicia en 31%*/
  text-align: end;
  margin-right: 5px;
}

.numsad1 {
  width: 21%;  /* Cambiar tama;o manualmente a medida que disminulla el numero inicia en 31%*/
  text-align: end;
  margin-right: 5px;
}

.numsad {
  width: 21%;  /* Cambiar tama;o manualmente a medida que disminulla el numero inicia en 21%*/
  text-align: end;
  margin-right: 5px;
}

.weds54 {
  margin-bottom: 15px;
}

.btn54 {
  margin-left: -10px;
}

.textarea11tx{
  text-align: center;
  resize: none;
  width: 620px;
  height: 24px;
  outline: none;
  margin-bottom: -7px;
  color: aliceblue;
}

.textarea11 {
  text-align: center;
  resize: none;
  width: 420px;
  height: 24px;
  outline: none;
  margin-bottom: -7px;
  color: aliceblue;
}

.cardwew {
  width: fit-content;
  margin: auto;
  margin-top: 5px;
}

.pmaas {
  margin-top: 54px;
  margin-left: 108px;
  position: absolute;
  font-size: 12px;
  color: aliceblue;
}

.textformpre input{
  color: aliceblue !important;
}

.textformpre {
  color: aliceblue !important;
}

.textformpre input::-webkit-outer-spin-button,
.textformpre input::-webkit-inner-spin-button {
  /* -webkit-appearance: none; */
  margin: 0;
}

.primaryBtn {
  margin-top: 12px;
  /* background: linear-gradient(to right, #7deffa 0%, #a0aee9 50%, #4d4d4d 100%); */
}

.lasd8 {
  width: fit-content;
  margin: auto;
  margin-bottom: 15px;
}

.flex5dsdane {
  width: fit-content;
  margin: auto;
  padding-bottom: 20px;
}

.contpre {
  /* padding-top: 40px; */
  /* padding-bottom: 20px; */
  background-color: #212428;
  min-height: 800px;
  /* height: 100%; */
}

.progpur {
  font-size: 50px;
}

.h1tpur {
  margin-top: 20px;
  font-size: 45px;
  color: aliceblue;
  font-weight: 100;
}

.layprogress {
  max-width: 1200px;
  margin: auto;
  padding-right: 8%;
  padding-left: 8%;
  margin-bottom: 10px;
}

.proglinear {
  border-radius: 3px;
}

.h4pur {
  padding-left: 9%;
  font-size: 16px;
  color: aliceblue;
  font-weight: 100;
}

.btnpur {
  /* background: linear-gradient(to right, #7deffa 0%, #a0aee9 50%, #4d4d4d 100%); */
  /* background: #f3976a; */
  margin-bottom: 20px;
  margin-top: 20px;
}

.btnpurds {
  /* background: linear-gradient(to right, #7deffa 0%, #a0aee9 50%, #4d4d4d 100%); */
  /* background:#f3976a; */
  margin-bottom: 20px;
}

.btnpurdsqq {
  /* background: linear-gradient(to right, #7deffa 0%, #a0aee9 50%, #4d4d4d 100%); */
  /* background: #f3976a; */
  margin-top: 20px;
}

.btnpurdsqsd {
  /* background: linear-gradient(to right, #7deffa 0%, #a0aee9 50%, #4d4d4d 100%); */
  /* background: #f3976a; */
  margin-bottom: 20px;
}

.v-progress-linear .v-progress-linear__determinate {
  -webkit-animation: cssProgressActive 2s linear infinite;
  animation: cssProgressActive 2s linear infinite;
  background-size: 35px 35px;
}

@-webkit-keyframes cssProgressActive {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 35px 35px;
  }
}
@keyframes cssProgressActive {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 35px 35px;
  }
}

@media all and (max-width: 1260px) { 
  .h4pur {
    font-size: 14px;
  }

  .divldacc {
    margin-left: 80px;
    margin-right: 80px;
  }

  .days54 {
    font-size: 18px;
  }
  
  .divprocir22 {
    margin-left: 30px;
  }
}

@media all and (max-width: 960px) { 
  .layflexsasxas {
    margin-top: -12px;
  }
  
  .divpur {
    font-size: 18px;
  }

  .progpur {
    font-size: 45px;
  }

  .days54 {
    font-size: 15px;
  }

  .h1titeld5 {
    font-size: 30px;
  }

  .h4pur {
    font-size: 13px;
  }

  .h3sdf {
    font-size: 18px;
  }

  .divldacc {
    margin-left: 45px;
    margin-right: 45px;
  }

  .h1tpur {
    font-size: 35px;
  }

  .divprocir22 {
    margin-left: 25px;
  }

  .contpre {
    min-height: 100%;
  }
} 

@media all and (max-width: 700px) { 
  .divldacc {
    margin-left: 30px;
    margin-right: 30px;
  }

  .divprocir22 {
    margin-left: 5px;
  }

  .progpur {
    font-size: 35px;
  }

  .flextextfd {
    padding-left: 20px;
    padding-right: 20px;
  }

  .flextextfd21 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media all and (max-width: 600px) { 
  .assaq3s {
    margin-top: -25px;
  }

  .layflexsasxas {
    margin-top: -35px;
  }

  .h1titeld5 {
    font-size: 22px;
  }

  .h4pur {
    font-size: 15px;
  }
  
  .divpur {
    font-size: 14px;
  }

  .progpur {
    font-size: 20px;
  }

  .days54 {
    font-size: 11px;
  }
  
  .h3sdf {
    font-size: 14px;
  }

  .divldacc {
    margin-left: 8px;
    margin-right: 8px;
  }
  
  .h1tpur {
    font-size: 30px;
  }
  .contpre {
    padding-top: 90px;
  }

  .card111111111 {
  /* margin-top: -200px; */
  margin-bottom: 0px;
  }

  .h44cadcal22 {
  font-size: 15px;
  }

  .h44cadcal {
    font-size: 15px;
  }

  .h44cadcala {
    font-size: 15px;
  }
}



@media all and (max-width: 400px) { 
  .btn {
    margin-bottom: -30px;
  }

  .pmaas {
    margin-left: 45px;
  }

  .textformpre {
    width: fit-content;
  }

  .textarea11 {
    font-size: 10px;
    width: 250px;
  }

  .assaq3s {
    margin-top: -45px;
  }
        
  .layflexsasxas {
    margin-top: -60px;
  }

  .h1titeld5 {
    font-size: 20px;
  }
          
  .h4pur {
    font-size: 10px;
  }

  .progpur {
    font-size: 18px;
  }

  .days54 {
    font-size: 8px;
  }

  .h1titeld5 {
    font-size: 15px;
  }

  .divpur {
    font-size: 12px;
  }

  .h3sdf {
    font-size: 12px;
  }

  .divprocir22 {
    margin-left: -3px;
  }

  .btnpurdsqq {
    margin-top: 50px;
  }
}
</style>


<script lang="js">
import Web3 from "web3";
import {store} from '../store/';
import axios from "axios";

export default  {
  name: 'PresaleStarted',
  props: [],

  async created() {
    // exe once on created object, first fill
    await this.fetchData();
  },

  async mounted () {
  // exe every one minute before compoent is rendered
  setInterval(async () => { 
     await this.fetchData(); 
  }, 1000 * 30); // 30 segs

    this.startTimer();

    if (window.ethereum) {
      this.ethereum = window.ethereum;
      this.installed = true;
      // console.log("conecte a metamask");

    } else {
      this.ethereum = null;
      // console.log ("Please Install Metamask!")
      }    
    },

  data () {
    return {
      size: "",
      days:0,
      hours:0,
      minutes:0,
      seconds:0,
      value: "",
      ethereum: null,
      ethAccount: "",
      installed: false,
      Tx: "",
      getCurrentRate: "",
      disconnect: true,
      balance: "",
      tokenBalance: "",
      crowdsaleBalance: "",
      tokenSold: "",
      databsc: "",
      squaredResult: "",
      showAmount: "",
      bonus1Bnb: "",
      amountrbzbnb: "",
      rules: [
        v => !!v || 'Required',
        v => v >= 0.1 || 'Min 0.01 BNB',
        v => v <= 100 || 'Max 100 BNB',
  ],
    }
  },

  methods: {

  numberTokenM: function(){
    const showAmount = this.showAmount;
    const getCurrentRate = this.getCurrentRate;
    const bonus1Bnb = this.bonus1Bnb;
    const amountrbzbnb = showAmount * getCurrentRate;

    if(showAmount >= 1){
      this.bonus1Bnb = (amountrbzbnb * 0.2);
    } else {
      this.bonus1Bnb = 0;
    }

    const squaredResult = amountrbzbnb;
    this.squaredResult = squaredResult;
    reset();

    function reset(){
      document.getElementById().value = 0;
    }
  },

    async fetchData() {
      let URL = "https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=0xe7e23b6087EF042EcB08AcD20eea6D441C7B5cb2&address=0xa23512c22303b91d84dda417f5bae8c62809b4de&tag=latest&apikey=9URCC32BNPF9JA18S3WJP8TMKTFIU723I2"
      try {
        let response =  await axios.get(URL);

        if (response.status === 200) {
            this.databsc = response.data;
            // console.log("databsc", (response.data.result / 1e+18));

            const tokenSold = (((3.3e+26 - response.data.result) / 1e+18).toFixed());
            this.tokenSold = tokenSold;
            // console.log("tokenSold", tokenSold);

            const value = (((3.3e+26 - response.data.result) * 100) / 3.3e+26).toFixed(2);
            this.value = value;
            // console.log("Value:", value);
        } // si el endpoint devulve un status https con error; el app no hara nada
      } catch(e) {
        // Si genera un Trow Error lo mostramos en consola como warn, relamente no nos intereza avisar al usuario nada de esto
        console.warning(e);
      }
    },

    wall() {
        this.$refs.wall.select();
        document.execCommand('copy');
    },

    walltx() {
        this.$refs.walltx.select();
        document.execCommand('copy');
    },

    async getAccount() {

      const accounts = await ethereum.request({ method: 'eth_requestAccounts' }) // We currently only ever provide a single account,
      this.ethAccount = accounts[0] // but the array gives us some room to grow.
      // console.log("accounts:", accounts);

      const web3 = new Web3(window.ethereum);
      web3.eth.getAccounts().then(accounts => {
      web3.eth.getBalance(accounts[0]).then(balance => {
      this.balance = web3.utils.fromWei(balance)
      // console.log("Balance:", web3.utils.fromWei(balance));
      })
    })

/*************************************** RBZ ****************************************************************************/

const getContract = async (web3) => {
  // const data = await $.getJSON("./contracts/Greeting.json");
        
          const contract = new web3.eth.Contract(Brz, tokenAddress);
          
          const tokenBalance =  contract.methods.balanceOf(accounts[0]).call();
          this.tokenBalance = web3.utils.fromWei(tokenBalance);
          // console.log("Token-Balances:", web3.utils.fromWei(tokenBalance));

}
        const tokenAddresses = [
            '0x5d0b9c2Bcbd61a56288a0F1ee6c0127F944a3Cfd',
        ];
        const Rbz = [
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
]
  
        for (let tokenAddress of tokenAddresses) {
          const web3 = new Web3(window.ethereum);
          const contract = new web3.eth.Contract(Rbz, tokenAddress);
          
          const tokenBalance = await contract.methods.balanceOf(accounts[0]).call();
          this.tokenBalance = web3.utils.fromWei(tokenBalance);
          // console.log("Token-Balances:", web3.utils.fromWei(tokenBalance));
        }
    },

    getMetamask: function() {
    window.open("https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn");
    },

    addToMetamask: function() {
      if(window.ethereum){
          const e="0x5d0b9c2Bcbd61a56288a0F1ee6c0127F944a3Cfd",
          t="RBZ",
          s=2,
          a="https://robizawinetoken.io/logo_favicon.jpg";
      try{
          window.ethereum.request({method:"wallet_watchAsset",params:{type:"ERC20",options:{address:e,symbol:t,decimals:s,image:a}}})}
              catch(e){}
              }else alert("Please install metamask...")
    },

    async sendEthTransaction() {

      const web3 = new Web3(window.ethereum);
      const amount = $('#numberToken').val()*1000000000000000000;
      const sendEthTransaction = await ethereum.request({
            method: 'eth_sendTransaction',
            params: [
            {
            from: ethereum.selectedAddress,
            to: '0xa23512c22303b91d84dda417f5bae8c62809b4de',
            value: web3.utils.toHex(amount)
          },
          ],
      })
      .then((txHash) => {
        // console.log(`Respuesta obj: ${txHash}`)
        this.Tx = txHash
      // console.log("Tx", txHash)
      })
      .catch((error) => console.error);
      },

    startTimer() {
      
      setInterval(() => {
      const now = new Date();
      const end = new Date("2022-07-01T00:00Z"); // inicio
      const end2 = new Date("2022-07-11T00:00Z");
      // const end3 = new Date("2022-07-09T00:00Z");
      // const end4 = new Date("2022-07-24T00:00Z");
      const end5 = new Date("2022-07-21T00:00Z"); // Finliza
      const distance = this.distance;
     
      if(now <= end){
        this.distance = end.getTime() - now.getTime();
        this.getCurrentRate = 80000;
      }
      if (now > end) {
        this.distance = end2.getTime() - now.getTime();
        this.getCurrentRate = 80000;
     }
      if (now >= end2) {
        this.distance = end5.getTime() - now.getTime();
        this.getCurrentRate = 65000;
      } 
      // if (now >= end3) {
      //   this.distance = end4.getTime() - now.getTime();
      //   this.getCurrentRate = 50000;
      // }
      // if (now >= end4) {
      //   this.distance = end5.getTime() - now.getTime();
      //   this.getCurrentRate = 40000;
      // }
      if (now > end5){
        this.getCurrentRate = 1;
        clearTimeout(timerID);
      }

      const days = Math.floor(this.distance / 86400000);
      const hours = Math.floor((this.distance % 86400000) / 3600000);
      const minutes = Math.floor((this.distance % 3600000) / 60000);
      const seconds = Math.floor((this.distance % 60000) / 1000);
     
      this.days = days < 10 ? "0" + days : days;
      this.hours = hours < 10 ? "0" + hours : hours;
      this.minutes = minutes < 10 ? "0" + minutes : minutes;
      this.seconds = seconds < 10 ? "0" + seconds : seconds;
      this.divider = 100 / this.value;
      }, 1000);
    }
  },

  computed: {
    size1 () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 40
        case 'sm': return 80
        case 'md': return 100
        case 'lg': return 120
        case 'xl': return 120
      }
    },

    width1 () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 2
        case 'sm': return 2
        case 'md': return 3
        case 'lg': return 3
        case 'xl': return 5
      }
    },
  }
}
</script>