<template>
    <div id="mint">
        <div class="crd85">
            <h1 class="sad85d"><b>ROBIZA</b> NFT Collections</h1>
            <div class="divsa2">

                <v-card class="sdas333">
                    <div class="sdivlsd6">
                        <v-img class="imf4wq" src="@/assets/animationsea.gif"></v-img>
                    </div>

                    <div class="sdivlsd6">
                        <h2 class="h2cards"><span>SEA</span> Membership</h2>
                        <p class="p1fwq">Price: 0.1 ETH</p>
                        <p class="p1fq">Collection of 1,200</p>
                        <v-card-actions class="cact5 justify-center">
                            <v-btn href="https://app.robizawinetoken.com/mintsea" color="white" class="dksa" outlined rounded :large="$vuetify.breakpoint.mdAndUp" :small="$vuetify.breakpoint.mdAndDown">
                                BUY
                            </v-btn>
                        </v-card-actions>                           
                    </div>

                    <div class="sdivlsd6">
                        <h2 class="h2fx">{{ $t('mint.title') }}</h2>
                        <p class="p12fq">{{ $t('mint.content1') }}</p>
                        <p class="p12fq">{{ $t('mint.content2') }}</p>
                        <p class="p12fq">{{ $t('mint.content3') }}</p>
                    </div>
                </v-card>

                <v-card class="sdas333">
                    <div class="sdivlsd6">
                        <v-img class="imf4wq" src="@/assets/animationsun.gif"></v-img>
                    </div>

                    <div class="sdivlsd6">
                        <h2 class="h2cards"><span>SUN</span> Membership</h2>
                        <p class="p1fwq">Price: 0.2 ETH</p>
                        <p class="p1fq">Collection of 1,000</p>
                        <v-card-actions class="cact5 justify-center">
                            <v-btn href="https://app.robizawinetoken.com/mintsun" color="white" class="dksa" outlined rounded :large="$vuetify.breakpoint.mdAndUp" :small="$vuetify.breakpoint.mdAndDown">
                                BUY
                            </v-btn>
                        </v-card-actions>                           
                    </div>

                    <div class="sdivlsd6">
                        <h2 class="h2fx">{{ $t('mint.title1-2') }}</h2>
                        <p class="p12fqq">{{ $t('mint.content1-2') }}</p>
                        <p class="p12fq">{{ $t('mint.content2-2') }}</p>
                        <p class="p12fq">{{ $t('mint.content3-2') }}</p>
                        <p class="p12fq">{{ $t('mint.content4-2') }}</p>
                    </div>
                </v-card>
                
                <v-card class="sdas333">
                    <div class="sdivlsd6">
                        <v-img class="imf4wq" src="@/assets/animationmoon.gif"></v-img>
                    </div>

                    <div class="sdivlsd6">
                        <h2 class="h2cards"><span>MOON</span> Membership</h2>
                        <p class="p1fwq">Price: 0.3 ETH</p>
                        <p class="p1fq">Collection of 800</p>
                        <v-card-actions class="cact5 justify-center">
                            <v-btn href="https://app.robizawinetoken.com/mintmoon" color="white" class="dksa" outlined rounded :large="$vuetify.breakpoint.mdAndUp" :small="$vuetify.breakpoint.mdAndDown">
                                BUY
                            </v-btn>
                        </v-card-actions>                           
                    </div>

                    <div class="sdivlsd6">
                        <h2 class="h2fx">{{ $t('mint.title1-3') }}</h2>
                        <p class="p12fqq">{{ $t('mint.content1-3') }}</p>
                        <p class="p12fq">{{ $t('mint.content2-3') }}</p>
                        <p class="p12fq">{{ $t('mint.content3-3') }}</p>
                        <p class="p12fq">{{ $t('mint.content4-3') }}</p>
                        <p class="p12fq">{{ $t('mint.content5-3') }}</p>
                        <p class="p12fq">{{ $t('mint.content6-3') }}</p>
                        <p class="p12fq">{{ $t('mint.content7-3') }}</p>
                    </div>
                </v-card>
            </div>
        </div>
    </div>
</template>
  
  
<script>
  import AOS from 'aos';
  import 'aos/dist/aos.css';
  
  export default {
    name: 'Mint',
  
    mounted() {
      AOS.init({
        disable:true
        // disable: function () {
        //   var maxWidth = 800;
        //   return window.innerWidth < maxWidth;
        // }
      });
    },
  }
</script>
  
  
<style>
#mint {
    /* height: 100%; */
    padding: 50px;
    /* background-image: url(../assets/mint6.jpg); */
    /* background: #212428; */

    /* background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; */
}

.sdivlsd6{
    width: 33%;
    margin: auto;
}

/* .sdivlsd61 {
    width: 33%;
    margin: auto;
} */

.p1fwq {
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    color: white;
}

.p1fq {
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    color: white;
    margin-top: -15px;
}

.h2fx {
    text-align: center;
    /* padding-left: 20px; */
    margin-bottom: 15px;
    font-size: 20px;
    color: white;
    margin-top: 40px;
    font-family: "Montserrat",Sans-serif;
}

.p12fq {
    font-size: 14px;
    text-align: left;
    color: white;
    font-family: "Montserrat",Sans-serif;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 300;
}

.p12fqq {
    font-size: 14px;
    text-align: left;
    color: white;
    font-family: "Montserrat",Sans-serif;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 500;
}

.h2cards {
    color:white;
    font-weight: 300;
    font-family: "Montserrat",Sans-serif;
    margin-bottom: 10px;
}

.h2cards span {
    font-weight: bold;
}

.sad85d {
    color:white;
    font-weight: 400;
    font-family: "Montserrat",Sans-serif; 
    padding-bottom: 10px;
    font-size: 40px;
}

.dksa {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.crd85 {
    /* background: rgba(59, 59, 59, 0.5) !important ; */
    margin: auto;
    /* width: 100%; */
    text-align: center;
    padding-top: 30px;
    margin-left: 100px;
    margin-right: 100px;

    /* margin-top: 10px; */
    margin-bottom: 50px;
}

.imf4wq {
    width: 280px;
    height: 280px;
    margin: auto;
    border-radius: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.cact5 {
    margin-top: 10px;
    margin-bottom: 20px;
}

.divsa2 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    /* align-items: flex-end; */
}

.sdas333 {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    background: rgba(70, 70, 70, 0.4) !important ;
    /* border: solid;
    border-color: #f3976a !important;
    border-width: 1px; */
    border-radius: 30px !important;
}

@media (max-width: 1264px) { 
    .imf4wq {
        width: 250px;
        height: 250px;
    }

    .divsa2 {
        margin-top: 0px;
    }
}

@media (max-width: 960px) {
    .imf4wq {
        width: 250px;
        height: 250px;
    }
    .h2fx {
        margin-bottom: 0px;
        font-size: 15px;
        margin-top: 0px;
    }
    .p12fq {
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .p12fqq {
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;
    }

     .sdivlsd6 {
        width: 100%;
        /* padding-left: 30px;
    padding-right: 30px; */
    }

    /*.sdivlsd61 {
        width: 33%;
    } */

    .sdas333 {
        display: inline;
        /* width: 100%; */
        /* width: 500px; */
        padding-left: 20px;
        padding-right: 20px;

    }

    .crd85 {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media (max-width: 600px) {
 .sdivlsd6 {
        width: 100%;
    }

        /*.sdivlsd61 {
        width: 33%;
    } */

    .sdas333 {
        display: inline;
        /* flex-wrap: wrap; */
    }

    .p12fq {

        padding-left: 0px;
        padding-right: 0px;
    }

    .p12fqq {
        padding-left: 0px;
        padding-right: 0px;
    }

    .crd85 {
        padding: 10px;
        /* padding-left: 30px;
    padding-right: 30px; */
    }
}
</style>
  