<template>
  <div class="divdpe63">
    <header class="headerterms">
      <h1 class="termsh1">{{ $t('policy.title') }}</h1>
      <p class="titlespan">{{ $t('policy.tit1') }}</p>
      <p class="titlespan">{{ $t('policy.tit2') }} <a href="mailto:info@robizawine.com">info@robizawine.com</a></p>
    </header>          
    <v-flex class="flexterms">
      <h2 class="h3terms">{{ $t('policy.tit1-1') }}</h2>
      <p class="titlespan">{{ $t('policy.cont1') }}</p>
      
      <ol class="titlespanx">
        <li class="lid2">{{ $t('policy.cont2') }}</li>
        <li class="lid2">{{ $t('policy.cont3') }}</li>
        <li class="lid2">{{ $t('policy.cont4') }}</li>
        <li class="lid2">{{ $t('policy.cont5') }}</li>
        <li class="lid2">{{ $t('policy.cont6') }}</li>
      </ol>

      <p class="titlespan">{{ $t('policy.cont7') }}</p>

      <h2 class="h3terms">{{ $t('policy.tit1-2') }}</h2>
      <p class="titlespan">{{ $t('policy.cont1-2') }}</p>
      <p class="titlespan">{{ $t('policy.cont2-2') }}</p>

      <h2 class="h3terms">{{ $t('policy.tit1-3') }}</h2>

      <p class="titlespan">{{ $t('policy.cont1-3') }}</p>
      <ol class="titlespanx">
        <li class="lid2">{{ $t('policy.cont2-3') }}</li>
      </ol>
      <p class="titlespan">{{ $t('policy.cont3-3') }}</p>
      <p class="titlespan">{{ $t('policy.cont4-3') }}</p>

      <h2 class="h3terms">{{ $t('policy.tit1-4') }}</h2>

      <p class="titlespan">{{ $t('policy.cont1-4') }}</p>
      <p class="titlespan">{{ $t('policy.cont2-4') }}</p>

      <ol class="titlespanx">
        <li class="lid2">{{ $t('policy.cont3-4') }}</li>
        <li class="lid2">{{ $t('policy.cont4-4') }}</li>
      </ol>

      <h2 class="h3terms">{{ $t('policy.tit1-5') }}</h2>
      <p class="titlespan">{{ $t('policy.cont1-5') }}</p>
      <p class="titlespan">{{ $t('policy.cont2-5') }}</p>
      <p class="titlespan">{{ $t('policy.cont3-5') }}</p>
      <ol class="titlespanx">
        <li class="lid2">{{ $t('policy.cont4-5') }}</li>
        <li class="lid2">{{ $t('policy.cont5-5') }}</li>
        <li class="lid2">{{ $t('policy.cont6-5') }}</li>
        <li class="lid2">{{ $t('policy.cont7-5') }}</li>
        <li class="lid2">{{ $t('policy.cont8-5') }}</li>
      </ol>

      <h2 class="h3terms">{{ $t('policy.tit1-6') }}</h2>
      <p class="titlespan">{{ $t('policy.cont1-6') }}</p>
      <p class="titlespan">{{ $t('policy.cont2-6') }}</p>
      <ol class="titlespanx">
        <li>{{ $t('policy.cont3-6') }}</li>
      </ol>

      <h2 class="h3terms">{{ $t('policy.tit1-7') }}</h2>
      <p class="titlespan">{{ $t('policy.cont1-7') }}</p>
      <p class="titlespan">{{ $t('policy.cont2-7') }}</p>
      <p class="titlespan">{{ $t('policy.cont3-7') }}</p>
      <p class="titlespan">{{ $t('policy.cont4-7') }}</p>
      <ol class="titlespanx">
        <li class="lid2">{{ $t('policy.cont5-7') }}</li>
      </ol>
      <p class="titlespan">{{ $t('policy.cont6-7') }}</p>
      <ol class="titlespanx">
        <li class="lid2">{{ $t('policy.cont7-7') }}</li>
      </ol>
      <p class="titlespan">{{ $t('policy.cont8-7') }}</p>
      <ol class="titlespanx">
        <li class="lid2">{{ $t('policy.cont9-7') }}.</li>
      </ol>
      <p class="titlespan">{{ $t('policy.cont10-7') }}</p>

      <p class="titlespan">{{ $t('policy.cont11-7') }}</p>
      <p class="titlespan">{{ $t('policy.cont12-7') }}</p>

      <ol class="titlespanx">
        <li class="lid2">{{ $t('policy.cont13-7') }}</li>
        <li class="lid2">{{ $t('policy.cont14-7') }}</li>
        <li class="lid2">{{ $t('policy.cont15-7') }}</li>
        <li class="lid2">{{ $t('policy.cont16-7') }}</li>
      </ol>
      <p class="titlespan">{{ $t('policy.cont17-7') }}</p>

      <h2 class="h3terms">{{ $t('policy.tit1-8') }}</h2>
      <p class="titlespan">{{ $t('policy.cont1-8') }}</p>

      <h2 class="h3terms">{{ $t('policy.tit1-9') }}</h2>
      <p class="titlespan">{{ $t('policy.cont1-9') }}</p>
      <p class="titlespan">{{ $t('policy.cont2-9') }}</p>

      <h2 class="h3terms">{{ $t('policy.tit1-10') }}</h2>
      <p class="titlespan">{{ $t('policy.cont1-10') }}</p>
      <ol class="titlespanx">
        <li class="lid2">{{ $t('policy.cont2-10') }}</li>
        <li class="lid2">{{ $t('policy.cont3-10') }}</li>
        <li class="lid2">{{ $t('policy.cont4-10') }}</li>
        <li class="lid2">{{ $t('policy.cont5-10') }}</li>
        <li class="lid2">{{ $t('policy.cont6-10') }}</li>
        <li class="lid2">{{ $t('policy.cont7-10') }}</li>
        <li class="lid2">{{ $t('policy.cont8-10') }}</li>
      </ol>

      <h2 class="h3terms">{{ $t('policy.tit1-11') }}</h2>
      <p class="titlespan">{{ $t('policy.cont1-11') }}</p>

      <h2 class="h3terms">{{ $t('policy.tit1-12') }}</h2>
      <p class="titlespan">{{ $t('policy.cont1-12') }}</p>
      <p class="titlespan">{{ $t('policy.cont2-12') }} <a href="mailto:info@robizawine.com">info@robizawine.com</a></p>

      <h2 class="h3terms">{{ $t('policy.tit1-13') }}</h2>
      <p class="titlespan">{{ $t('policy.cont1-13') }}</p>
      <p class="titlespan">{{ $t('policy.cont2-13') }}</p>
      <ol class="titlespanx">
        <li class="lid2">{{ $t('policy.cont3-13') }}</li>
      </ol>
      <p class="titlespan">{{ $t('policy.cont4-13') }}</p>
      <ol class="titlespanx">
        <li class="lid2">{{ $t('policy.cont5-13') }}</li>
      </ol>
      <p class="titlespan">{{ $t('policy.cont6-13') }}</p>
      <ol class="titlespanx">
        <li class="lid2">{{ $t('policy.cont7-13') }}</li>
      </ol>
      <p class="titlespan">{{ $t('policy.cont8-13') }}</p>

      <h2 class="h3terms">{{ $t('policy.tit1-14') }}</h2>
      <p class="titlespan">{{ $t('policy.cont1-14') }}</p>

      <h2 class="h3terms">{{ $t('policy.tit1-15') }}</h2>
      <p class="titlespan">{{ $t('policy.cont1-15') }}</p>

      <h2 class="h3terms">{{ $t('policy.tit1-16') }}</h2>
      <p class="titlespan">{{ $t('policy.cont1-16') }}</p>
      <p class="titlespan"><a href="mailto:info@robizawine.com">info@robizawine.com</a></p>
    </v-flex> 
  </div>
</template>


<script>

export default {
  name: 'PrivacyPolicy',
  components: {
  }
}
</script>


<style>
.lid2 {
  padding-bottom: 15px;
}

.divdpe63 {
  width: 80%;
  margin: auto;
}

.h3terms1 {
  margin-bottom: 10px;
}

.h3terms {
  margin-top: 40px;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: "Montserrat",Sans-serif;
}

.headerterms {
  margin-top: 40px;
}

.termsh1 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 40px;
  font-family: "Montserrat",Sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
}

.titlespan {
  font-family: "Montserrat",Sans-serif;
  padding-left: 40px;
  padding-right: 40px;
}

.titlespanx {
  font-family: "Montserrat",Sans-serif;
  margin-left: 60px;
  margin-bottom: 10px;
}

.flexterms {
  margin: auto;
  margin-bottom: 100px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: justify;
}

@media all and (min-width: 600px) and (max-width: 960px) {
  .divdpe63 {
    width: 90%;
    margin: auto;
  }
}

@media all and (max-width: 600px) {
  .divdpe63 {
    width: 100%;
    margin: auto;
  }
  .titlespan {
    font-size: 12px;
  }
  .titlespanx {
    font-size: 12px;
  }
  .termsh1 {
    font-size: 30px;
  }
  .h3terms {
    font-size: 20px;
  }
  .lid2 {
    padding-bottom: 10px;
  }
}
</style>
