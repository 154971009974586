<template>
  <div class="divdpe63">
    <header class="headerterms">
      <h1 class="termsh1">{{ $t('terms.title') }}</h1>
    </header>          
    <v-flex class="flexterms">
      <h2 class="h3terms">{{ $t('terms.tit1') }}</h2>
      <p class="titlespan">{{ $t('terms.cont1') }}</p>
      <p class="titlespan">{{ $t('terms.cont2') }}</p>
      
      <p class="titlespan">{{ $t('terms.cont3') }}</p>
      <p class="titlespan">{{ $t('terms.cont4') }}</p>
      <p class="titlespan">{{ $t('terms.cont5') }}</p>
      <p class="titlespan">{{ $t('terms.cont6') }}</p>
      <p class="titlespan">{{ $t('terms.cont7') }}</p>

      <h2 class="h3terms">{{ $t('terms.tit2') }}</h2>
      <p class="titlespan"><b>{{ $t('terms.cont1-2-n') }}</b> {{ $t('terms.cont2-2') }}</p>
      <p class="titlespan"><b>{{ $t('terms.cont3-2-n') }}</b> {{ $t('terms.cont4-2') }}</p>
      
      <h2 class="h3terms">{{ $t('terms.tit3') }}</h2>
      <p class="titlespan">{{ $t('terms.cont1-3') }}</p>
      
      <h2 class="h3terms">{{ $t('terms.tit4') }}</h2>
      <p class="titlespan">{{ $t('terms.cont1-4') }}</p>
      <p class="titlespan">{{ $t('terms.cont2-4') }}</p>
      <p class="titlespan">{{ $t('terms.cont2-4') }}</p>
      <p class="titlespan"><b>{{ $t('terms.cont4-4-n') }}</b> {{ $t('terms.cont5-4') }}</p>
      <p class="titlespan">{{ $t('terms.cont6-4') }}</p>
      <p class="titlespan"><b>{{ $t('terms.cont7-4-n') }}</b> {{ $t('terms.cont8-4') }}</p>
      
      <h2 class="h3terms">{{ $t('terms.tit5') }}</h2>
      <p class="titlespan">{{ $t('terms.cont1-5') }}</p>
      <p class="titlespan">{{ $t('terms.cont2-5') }}</p>
      
      <p class="titlespana">{{ $t('terms.cont3-5-n') }}</p>
      <p class="titlespan">{{ $t('terms.cont4-5') }}</p>
      
      <p class="titlespana">{{ $t('terms.cont5-5-n') }}</p>
      <p class="titlespan">{{ $t('terms.cont6-5') }}</p>
      
      <p class="titlespana">{{ $t('terms.cont7-5-n') }}</p>
      <p class="titlespan">{{ $t('terms.cont8-5') }}</p>

      <p class="titlespana">{{ $t('terms.cont9-5-n') }}</p>
      <p class="titlespan">{{ $t('terms.cont10-5') }}</p>
      
      <p class="titlespana">{{ $t('terms.cont11-5-n') }}</p>
      <p class="titlespan">{{ $t('terms.cont12-5') }}</p>
      <p class="titlespan">{{ $t('terms.cont13-5') }}</p>

      <h2 class="h3terms">{{ $t('terms.tit6') }}</h2>
      <p class="titlespan">{{ $t('terms.cont1-6') }}</p>
      <ul class="titlespanx">
        <li class="lid2">{{ $t('terms.cont2-6') }}</li>
        <li class="lid2">{{ $t('terms.cont3-6') }}</li>
        <li class="lid2">{{ $t('terms.cont4-6') }}</li>
        <li class="lid2">{{ $t('terms.cont5-6') }}</li>
      </ul>
      <p class="titlespan">{{ $t('terms.cont6-6') }}</p>
      <p class="titlespan">{{ $t('terms.cont7-6') }}</p>
      
      <h2 class="h3terms">{{ $t('terms.tit7') }}</h2>
      <p class="titlespan">{{ $t('terms.cont1-7') }}</p>
      <p class="titlespan"><b>{{ $t('terms.cont2-7-n') }}</b> {{ $t('terms.cont3-7') }}</p>
      <p class="titlespan"><b>{{ $t('terms.cont4-7-n') }}</b> {{ $t('terms.cont5-7') }}</p>
      <ul class="titlespanx">
        <li class="lid2">{{ $t('terms.cont6-7') }}</li>
        <li class="lid2">{{ $t('terms.cont7-7') }}</li>
        <li class="lid2">{{ $t('terms.cont8-7') }}</li>
        <li class="lid2">{{ $t('terms.cont9-7') }}</li>
        <li class="lid2">{{ $t('terms.cont10-7') }}</li>
        <li class="lid2">{{ $t('terms.cont11-7') }}</li>
        <li class="lid2">{{ $t('terms.cont12-7') }}</li>
        <li class="lid2">{{ $t('terms.cont13-7') }}</li>
        <li class="lid2">{{ $t('terms.cont14-7') }}</li>
      </ul>
      <p class="titlespan"><b>{{ $t('terms.cont15-7-n') }}</b> {{ $t('terms.cont16-7') }}</p>

      <h2 class="h3terms">{{ $t('terms.tit8') }}</h2>
      <p class="titlespan">{{ $t('terms.cont1-8') }}</p>
      <p class="titlespan">{{ $t('terms.cont2-8') }}</p>

      <h2 class="h3terms">{{ $t('terms.tit9') }}</h2>
      <p class="titlespan">{{ $t('terms.cont1-9') }}</p>
      <p class="titlespan">{{ $t('terms.cont2-9') }}</p>

      <h2 class="h3terms">{{ $t('terms.titlpria10') }}</h2>
      <p class="titlespana">{{ $t('terms.tit10-1') }}</p>
      <p class="titlespan">{{ $t('terms.cont1-10') }}</p>
      <p class="titlespan">{{ $t('terms.cont2-10') }}</p>
      <p class="titlespan">{{ $t('terms.cont3-10') }}</p>

      <p class="titlespana">{{ $t('terms.tit10-2') }}</p>
      <p class="titlespan">{{ $t('terms.cont4-10') }}</p>
      <p class="titlespan">{{ $t('terms.cont5-10') }}</p>
      <p class="titlespan">{{ $t('terms.cont6-10') }}</p>

      <h2 class="h3terms">{{ $t('terms.tit11') }}</h2>
      <p class="titlespan"><b>{{ $t('terms.cont1-11-n') }}</b> {{ $t('terms.cont2-11') }}</p>
      <p class="titlespan">{{ $t('terms.cont3-11') }} <i>{{ $t('terms.cont4-11-i') }}</i></p>

      <h2 class="h3terms">{{ $t('terms.tit12') }}</h2>
      <p class="titlespan">{{ $t('terms.cont1-12') }}</p>
      <p class="titlespan">{{ $t('terms.cont2-12') }}</p>

      <h2 class="h3terms">{{ $t('terms.tit13') }}</h2>
      <p class="titlespan">{{ $t('terms.cont1-13') }}</p>

      <h2 class="h3terms">{{ $t('terms.tit14') }}</h2>
      <p class="titlespan">{{ $t('terms.cont1-14') }}</p>

      <h2 class="h3terms">{{ $t('terms.tit15') }}</h2>
      <p class="titlespan">{{ $t('terms.cont1-15') }}</p>

      <h2 class="h3terms">{{ $t('terms.tit16') }}</h2>
      <p class="titlespan"><b>{{ $t('terms.cont1-16-n') }}</b> {{ $t('terms.cont2-16') }}</p>
      <p class="titlespan"><b>{{ $t('terms.cont3-16-n') }}</b> {{ $t('terms.cont4-16') }}</p>
      <p class="titlespan"><b>{{ $t('terms.cont5-16-n') }}</b> {{ $t('terms.cont6-16') }}</p>
      <p class="titlespan"><b>{{ $t('terms.cont7-16-n') }}</b> {{ $t('terms.cont8-16') }}</p>

      <h2 class="h3terms">{{ $t('terms.tit17') }}</h2>
      <ol class="titlespanx">
        <li class="lid2">{{ $t('terms.cont1-17') }}</li>
        <li class="lid2">{{ $t('terms.cont2-17') }}</li>
        <li class="lid2">{{ $t('terms.cont3-17') }}</li>
        <li class="lid2">{{ $t('terms.cont4-17') }}</li>
        <li class="lid2">{{ $t('terms.cont5-17') }}</li>
        <li class="lid2">{{ $t('terms.cont6-17') }}</li>
        <li class="lid2">{{ $t('terms.cont7-17') }}</li>
        <li class="lid2">{{ $t('terms.cont8-17') }}</li>
        <li class="lid2">{{ $t('terms.cont9-17') }}</li>
        <li class="lid2">{{ $t('terms.cont10-17') }}</li>
      </ol>
    </v-flex> 
  </div>
</template>


<script>

export default {
  name: 'Terms',
  components: {
  }
}
</script>


<style>
.lid2 {
  padding-bottom: 15px;
}

.divdpe63 {
  width: 80%;
  margin: auto;
}

.h3terms1 {
  margin-bottom: 10px;
}

.h3terms {
  margin-top: 40px;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: "Montserrat",Sans-serif;
}

.headerterms {
  margin-top: 40px;
}

.termsh1 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 40px;
  font-family: "Montserrat",Sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
}

.titlespan {
  font-family: "Montserrat",Sans-serif;
  padding-left: 40px;
  padding-right: 40px;
}

.titlespana {
  font-family: "Montserrat",Sans-serif;
  padding-left: 40px;
  padding-right: 40px;
  font-weight: bold;
}

.titlespanx {
  font-family: "Montserrat",Sans-serif;
  margin-left: 60px;
  margin-bottom: 10px;
}

.flexterms {
  margin: auto;
  margin-bottom: 100px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: justify;
}

@media all and (min-width: 600px) and (max-width: 960px) {
  .divdpe63 {
    width: 90%;
    margin: auto;
  }
}

@media all and (max-width: 600px) {
  .divdpe63 {
    width: 100%;
    margin: auto;
  }
  .titlespan {
    font-size: 12px;
  }
  .titlespanx {
    font-size: 12px;
  }
  .termsh1 {
    font-size: 30px;
  }
  .h3terms {
    font-size: 20px;
  }
  .lid2 {
    padding-bottom: 10px;
  }
}

</style>
